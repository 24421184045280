// @ts-ignore
import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby";

type QueryProps = {
    site: {
        siteMetadata: {
            title: string
            menuLinks: {
                name: string,
                link: string
            }[]
        }
    }
}

const Header: React.FC = () => {
    const data = useStaticQuery<QueryProps>(graphql`
    query {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
    `)

    return (
            <header
                style={{
                    background: `#53DDFF`,
                    // background: `#00FF00`,
                    marginBottom: `1.45rem`,
                }}
            >
                <div
                    style={{
                        margin: `0 auto`,
                        maxWidth: 960,
                        padding: `1.45rem 1.0875rem`,
                    }}
                >
                    <h1 style={{ margin: 0 }}>
                        <Link
                            to="/"
                            style={{
                                color: `black`,
                                textDecoration: `none`,
                            }}
                        >
                            {data.site.siteMetadata.title}
                        </Link>
                    </h1>
                    <div>
                        <nav>
                            <ul style={{ display: "flex", flex: 1 }}>
                                {data.site.siteMetadata.menuLinks.map(link => (
                                    <li
                                        key={link.name}
                                        style={{
                                            listStyleType: `none`,
                                            padding: `1rem`,
                                        }}
                                    >
                                        <Link style={{ color: `black` }} to={link.link}>
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
    )
}



export default Header
